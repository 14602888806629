export const state = {
  menus: [
    {
      title: "Ana Sayfa",
      icon: "mdi-home",
      to: "/",
    },
    {
      title: "Kullanıcılar",
      icon: "mdi-information",
      to: "/users",
    },
    {
      title: "Mülkler",
      icon: "mdi-email",
      to: "/properties",
    },
  ],

  adminMenu: [
    {
      title: "Ana Sayfa",
      icon: "mdi-home",
      to: "/",
    },
    {
      title: "Kullanıcılar",
      icon: "mdi-information",
      to: "/users",
    },
    {
      title: "Mülkler",
      icon: "mdi-email",
      to: "/properties",
    },
  ],

  tenantMenu: [
    {
      title: "Ana Sayfa",
      icon: "mdi-home",
      to: "/",
    },
    {
      title: "Mülklerim",
      icon: "mdi-information",
      to: "/my-properties",
    },
    {
      title: "Bilgilerim",
      icon: "mdi-email",
      to: "/profile",
    },
  ],
};

export const getters = {
  menus: (state) => state.menus,
  adminMenu: (state) => state.adminMenu,
  tenantMenu: (state) => state.tenantMenu,
};

export const mutations = {
  setMenus(state, newValue) {
    state.menus = newValue;
  },
  setAdminMenu(state) {
    state.menus = state.adminMenu;
  },
  setTenantMenu(state) {
    state.menus = state.tenantMenu;
  },
};

export const actions = {};
